import React from "react";
import { FaTimes } from "react-icons/fa";

export const MessageModal = ({ closeModal, message }) => {
  return (
    <div>
      <FaTimes
        style={{ cursor: "pointer" }}
        className="absolute right-6 top-6 text-red-500"
        onClick={closeModal}
      />
      <p className="fw-600 text-lg">{message.title}</p>
      {message.supportingDocument && (
        <div className="mt-6">
          <img
            src={message.supportingDocument}
            alt="announce"
            className="w-full h-[300px] object-cover"
          />
        </div>
      )}
      <div className="mt-3 mb-3">
        <p className="whitespace-pre-line">{message.content}</p>
      </div>
    </div>
  );
};
