import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-tailwind/react";
import React from "react";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsList } from "react-icons/bs";
import { HiMenuAlt3, HiOutlineShoppingBag } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function Header() {
  // const navigate = useNavigate()

  const auth = useSelector((state) => state.auth);
  let carts = useSelector((state) => state.cart.cart);
  carts = auth.isAuthenticated
    ? carts.filter((where) => where.email === auth.user.email)
    : carts.filter((where) => where.email === null);

  const getTotalQuantity = carts.reduce((sum, cart) => {
    return sum + cart.quantity;
  }, 0);

  const [menuShow, setMenuShow] = useState(false);
  const [homeMenu, setHomeMenu] = useState(false);

  const CloseMenu = () => {
    setMenuShow(false);
  };
  const CloseHome = () => {
    setHomeMenu(false);
  };

  const errorRequest = () => {
    Swal.fire({
      icon: "error",
      text: "Kindly sign up as a client to request for a service provider",
      confirmButton: "OK",
    });
  };

  return (
    <div className="relative z-10 bg-white">
      <div className="box">
        <div className="justify-between items-center flex py-2 pb-3">
          <div className="w-4/12 hidden lg:flex items-center">
            <ul className="flex xl:font-600 fw-600 fw-600 w-full justify-between">
              <li>
                <BsList
                  className="xl:text-3xl text-2xl cursor-pointer transition hover:scale-110"
                  onClick={() => setHomeMenu(true)}
                />
              </li>
              <li>
                <Link
                  to="/shop"
                  className="block hover:border-b-[3px] border-blue-800 hover:text-blue-800 hover:scale-x-105 duration-100"
                >
                  Products
                </Link>
              </li>
              {auth?.user?.userType !== "professional" &&
              auth?.user?.userType !== "admin" &&
              auth?.user?.userType !== "vendor" ? (
                <li>
                  <Link
                    to="/services"
                    className="block hover:border-b-[3px] border-[#ec8b20] hover:text-secondary hover:scale-x-105 duration-100"
                  >
                    <span className="extra3">Request for </span>Service Provider
                  </Link>
                </li>
              ) : (
                <li className="cursor-pointer" onClick={errorRequest}>
                  <span className="extra3">Request for </span>Service Provider
                </li>
              )}
            </ul>
          </div>
          <div className="w-4/12 flex justify-center">
            <Link to="/">
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1669563824/BOG/logo_1_1_ubgtnr.png"
                alt="logo"
                className="lg:w-40 "
              />
            </Link>
          </div>
          <div className="w-4/12 hidden fw-600 lg:flex justify-end items-center">
            <Link to="/carts">
              <div className="text-xl mx-6 relative px-2 py-2 circle bg-light">
                <HiOutlineShoppingBag />
                <p className="absolute -top-2 right-0 fs-500 text-red-500">
                  {getTotalQuantity || 0}
                </p>
              </div>
            </Link>
            {auth?.isAuthenticated ? (
              <Link to="/dashboard" className="text-primary">
                <button>Dashboard</button>
              </Link>
            ) : (
              <>
                <Link to="/login" className="text-primary">
                  <button>Login</button>
                </Link>
                <Link
                  to="/signup"
                  className="ml-7 px-6 bg-primary text-white py-2 rounded"
                >
                  <button>Get Started</button>
                </Link>
              </>
            )}
          </div>
          <div className="text-black lg:hidden flex items-center">
            <Link to="/carts">
              <div className="text-xl mx-6 relative px-2 py-2 circle bg-light">
                <HiOutlineShoppingBag />
                <p className="absolute -top-2 right-0 fs-500 text-red-500">
                  {getTotalQuantity || 0}
                </p>
              </div>
            </Link>
            <HiMenuAlt3
              className="text-3xl fw-700"
              onClick={() => {
                setMenuShow(true);
              }}
            />
          </div>
        </div>
      </div>
      {menuShow && (
        <div className="w-full bg-op fixed lg:hidden z-40 top-0 min-h-screen">
          <div className="absolute menu lg:hidden w-10/12 bg-white px-6 z-40 top-0 min-h-screen">
            <div className="flex justify-between pt-5">
              <img
                src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1669563824/BOG/logo_1_1_ubgtnr.png"
                alt="logo"
                className="w-36"
              />
              <MdOutlineCancel
                onClick={() => {
                  setMenuShow(false);
                }}
              />
            </div>
            <div className="mt-5">
              <ul className="list-none">
                <li className="py-4">
                  <Link to="/">Home</Link>
                </li>
                <li className="py-4">
                  <Link to="/about">About</Link>
                </li>
                <li className="py-4">
                  <Link to="/shop">Shop for Products</Link>
                </li>
                <li className="py-4">
                  <Link to="/services">Request for Service Provider</Link>
                </li>
                <li className="py-4">
                  <Link to="/blog">Our Blog</Link>
                </li>
                <li className="py-4">
                  <Link to="/become-partner">Become a Partner</Link>
                </li>
                <li className="py-4">
                  <Link to="/jointeam">Join Our Team</Link>
                </li>
                <li className="py-4">
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li className="py-4">
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li className="pt-8" onClick={CloseMenu}>
                  {auth?.isAuthenticated ? (
                    <Link to="/dashboard">
                      <Button className="px-10 bg-primary">Dashboard</Button>
                    </Link>
                  ) : (
                    <div>
                      <Link to="/login">
                        <Button className="px-10 bg-primary">Login</Button>
                      </Link>
                      <Link to="/signup">
                        <Button className="px-10 bg-transparent text-black ml-3">
                          Sign Up
                        </Button>
                      </Link>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {homeMenu && (
        <div
          className="w-full  bg-op fixed z-40 top-0 min-h-screen"
          onClick={CloseHome}
        >
          <div className="absolute menu  w-3/12 bg-primary text-white pl-10 pr-6 z-40 top-0 min-h-screen">
            <div className="flex justify-between pt-4">
              <Link to="/">
                <img
                  src={require("../../assets/images/BOOG 1.png")}
                  alt="logo"
                  className="w-36"
                />
              </Link>
              <FontAwesomeIcon
                icon={faTimes}
                className="text-3xl cursor-pointer"
                onClick={CloseHome}
              />
            </div>
            <div className="mt-8 text-xl fw-500">
              <ul>
                <li className="py-4">
                  <Link to="/">Home</Link>
                </li>
                <li className="py-4">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="py-4">
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li className="py-4">
                  <Link to="/blog">Our Blog</Link>
                </li>
                <li className="py-4">
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li className="py-4">
                  <Link to="/jointeam">Join our Team</Link>
                </li>
                <li className="py-4">
                  <Link to="/become-partner">Become a Partner</Link>
                </li>
                <li className="py-4">
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li className="py-4">
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
                <li>
                  <ul className="mt-6 flex">
                    <li>
                      <a
                        href="https://www.instagram.com/build_on_the_go/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/images/insta.png")}
                          alt="insta"
                        />
                      </a>
                    </li>
                    <li className="pl-4">
                      <a
                        href="https://twitter.com/BogLimited44612"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/images/twitter.png")}
                          alt="twitter"
                        />
                      </a>
                    </li>
                    <li className="pl-4">
                      <a
                        href="https://www.linkedin.com/company/build-on-the-go/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/images/linkedn.png")}
                          alt="linkedn"
                        />
                      </a>
                    </li>
                    <li className="pl-4">
                      <a
                        href="https://www.facebook.com/profile.php?id=100091812647846"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/images/facebook.png")}
                          alt="facebook"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
