import dayjs from "dayjs";
import React from "react";
import ReactApexChart from "react-apexcharts";

export default function UsersChart({
  completedOrder,
  cancelledOrder,
  confirmedOrder,
  shippedOrder,
  year,
}) {
  const completed = completedOrder.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const confirmed = confirmedOrder.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const shipped = shippedOrder.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const cancelled = cancelledOrder.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const options = {
    labels: ["Confirmed", "Shipped", "Completed", "Cancelled"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [
    confirmed.length,
    shipped.length,
    completed.length,
    cancelled.length,
  ]; //our data

  return (
    <div className="">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="100%"
      />
    </div>
  );
}

export function ProjectChart({
  completedProjects,
  ongoingProjects,
  cancelledProjects,
  pendingProjects,
  year,
}) {
  const completed = completedProjects.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const ongoing = ongoingProjects.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const overdue = pendingProjects.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );
  const cancelled = cancelledProjects.filter(
    (where) => dayjs(where.createdAt).year() === Number(year)
  );

  const options = {
    labels: ["Completed", "Ongoing", "Overdue", "Cancelled"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const series = [
    completed.length,
    ongoing.length,
    overdue.length,
    cancelled.length,
  ]; //our data

  return (
    <div className="">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width="100%"
      />
    </div>
  );
}
