export const SET_ALERT = "SET_ALERT";
export const SET_ERROR = "SET_ERROR";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_GITHUB = "GET_GITHUB";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const ADD_TO_CART = "ADD_CART";
export const UPDATE_CART = "UPDATE_CART";
export const INCREMENT_QUANTITY = "ADD_QUANTITY";
export const DECREMENT_QUANTITY = "MINUS_QUANTITY";
export const GET_CART = "GET_CART";
export const DELETE_FROM_CART = "DELETE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORY_PRODUCT = "FETCH_CATEGORY_PRODUCT";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const FETCH_USER_PRODUCTS = "FETCH_USER_PRODUCTS";
export const FETCH_ADMIN_PRODUCTS = "FETCH_ADMIN_PRODUCTS";
export const FETCH_ADMIN_ORDERS = "FETCH_ADMIN_ORDERS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const UPDATE_ADMIN_PRODUCT_STATUS = "UPDATE_ADMIN_PRODUCT_STATUS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADMIN_DELETE_PRODUCT = "ADMIN_DELETE_PRODUCT";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_USER_ORDERS = "FETCH_USER_ORDERS";
export const USER_ERROR = "USER_ERROR";
export const LOADING = "LOADING";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const FETCH_MY_PROJECTS = "FETCH_MY_PROJECTS";
export const SAVE_ROLE = 'SAVE_ROLE';
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const APPROVE_PROJECT = "APPROVE_PROJECT";
export const FETCH_ALL_PROJECTS = "FETCH_All_PROJECTS";
export const FETCH_ALL_SERVICES = "FETCH_All_SERVICES";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const FETCH_ALL_POSTS = "FETCH_ALL_POSTS";
export const FETCH_POST_CATEGORY = "FETCH_POST_CATEGORY";
export const ADD_POST_CATEGORY = "ADD_POST_CATEGORY";
export const EDIT_POST_CATEGORY = "EDIT_POST_CATEGORY";
export const DELETE_POST_CATEGORY = "DELETE_POST_CATEGORY";
export const DELETE_POST = "DELETE_POST";
export const CREATE_POST = "CREATE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const POST_ERROR = "POST_ERROR";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const FETCH_ADMIN = "FETCH_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const FETCH_ADMIN_NOTIFICATIONS = "FETCH_ADMIN_NOTIFICATIONS";
export const DELETE_ADMIN_NOTIFICATIONS = "DELETE_ADMIN_NOTIFICATIONS";
export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const DELETE_USER_NOTIFICATIONS = "DELETE_USER_NOTIFICATIONS";
export const FETCH_ALL_ANNOUNCEMENTS = "FETCH_ALL_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const CREATE_ANNOUNCEMENT = "CREATE_ANNOUNCEMENT";
export const ANNOUNCEMENT_ERROR = "ANNOUNCEMENT_ERROR";
export const FETCH_SERVICE_CATEGORY = "FETCH_SERVICE_CATEGORY";
export const DELETE_SERVICE_CATEGORY = "DELETE_SERVICE_CATEGORY";
export const CREATE_SERVICE_CATEGORY = "CREATE_SERVICE_CATEGORY";
export const UPDATE_SERVICE_CATEGORY = "UPDATE_SERVICE_CATEGORY";
export const SERVICE_CATEGORY_ERROR = "SERVICE_CATEGORY_ERROR";
export const FETCH_DISPATCHED_PROJECTS = "FETCH_DISPATCHED_PROJECTS";
export const FETCH_ASSIGNED_PROJECTS = "FETCH_ASSIGNED_PROJECTS";
export const ASSIGN_PROJECT = "ASSIGN_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const FETCH_SUBSCRIPTION_PLANS = "FETCH_SUBSCRIPTION_PLANS";
export const DELETE_SUBSCRIPTION_PLAN = "DELETE_SUBSCRIPTION_PLAN";
export const CREATE_SUBSCRIPTION_PLAN = "CREATE_SUBSCRIPTION_PLAN";
export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN";
export const SUBSCRIPTION_PLAN_ERROR = "SUBSCRIPTION_PLAN_ERROR";
export const FETCH_SERVICE_FORMS = "FETCH_SERVICE_FORMS";
export const FETCH_KYC = "FETCH_KYC";
export const FETCH_SELECTED_PARTNERS = "FETCH_SELECTED_PARTNERS";
export const FETCH_COMMITMENT_FEE = "FETCH_COMMITMENT_FEE";
export const FETCH_ANALYZE_USERS = "FETCH_ANALYZE_USERS";
export const SYNC_CART = "SYNC_CART"


