import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";
import Axios from "../../../../../config/config";
import { Loader } from "../../../../layouts/Spinner";
import React, { useState, useEffect } from "react";
import { FormTypes } from "./FormTypes";

export default function ProjectFile() {
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");
  const [project, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);

  const getProjectDetail = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "Application/json",
          authorization: localStorage.getItem("auth_token"),
        },
      };
      setLoading(true);
      const url = `projects/v2/view-project/${projectId}`;
      const response = await Axios.get(url, config);
      const { data } = response;
      setProjects(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectDetail();
    // eslint-disable-next-line
  }, []);

  if (loading || !project) {
    return (
      <center>
        <Loader />
      </center>
    );
  }

  return (
    <div>
      {project && (
        <div className="min-h-screen fs-500 relative">
          <div className="w-full py-8 bg-white px-4">
            <p className="text-2xl fw-600 lg:flex items-center">
              Project ID:{" "}
              <span className="text-primary px-3">{project?.projectSlug}</span>{" "}
              <span className="text-sm text-orange-500 fw-500 bg-light px-2">
                {project?.approvalStatus}
              </span>
            </p>
            <p className="fs-400 text-gray-600 mt-2">View project details</p>
            <Breadcrumbs className="bg-white pl-0 mt-4">
              <Link to="/" className="opacity-60">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </Link>
              <Link to="/dashboard" className="opacity-60">
                <span>Dashboard</span>
              </Link>
              <Link to="" className="opacity-60">
                <span>project</span>
              </Link>
              <Link to="" className="">
                <span>Project File</span>
              </Link>
            </Breadcrumbs>
          </div>
          {/*projet form details */}
          <div className="lg:p-5 px-2 py-4">
            <FormTypes data={project} />
          </div>
        </div>
      )}
    </div>
  );
}
