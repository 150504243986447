import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Axios from "../config/config";
import toast from "react-hot-toast";

const usePostHook = () => {
  const [loading, setLoading] = useState(false);
  const handlePost = useCallback(async (url, payload, type, success) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": type,
          authorization: `${localStorage.getItem("auth_token")}`,
        },
      };
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/${url}`,
        payload,
        config
      );
      success();
    } catch (error) {
      // toast.error(error.response.data.message);
      setLoading(false);
      // console.log(error);
    }
  }, []);
  return { handlePost };
};
export default usePostHook;
