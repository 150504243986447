import { RiDeleteBin5Line } from "react-icons/ri";
import ReactTimeAgo from "react-time-ago";
import { markNotificationAsRead } from "../../../../../services/endpoint";
import "toasted-notes/src/styles.css";
import { useDispatch } from "react-redux";
import {
  deleteAdminNotification,
  deleteUserNotification,
} from "../../../../../redux/actions/notifications";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Avatar, Button } from "@material-tailwind/react";
import Spinner from "../../../../layouts/Spinner";
import { useState } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NotificationItem = ({ item, reload, isAdmin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const stopLoading = () => {
    setLoading(false);
  };

  const [deleteItem, setDeleteItem] = useState("");

  const readNotification = async () => {
    await markNotificationAsRead(item.id)
      .then(() => {
        reload();
      })
      .catch(() => {});
  };

  const deleteModal = (id) => {
    setModalDelete(true);
    setDeleteItem(id);
  };

  const deleteNotification = async () => {
    setLoading(true);
    if (isAdmin) {
      dispatch(deleteAdminNotification(deleteItem, stopLoading));
      if (!loading) {
        reload();
      }
    } else {
      dispatch(deleteUserNotification(deleteItem, stopLoading));
      if (!loading) {
        reload();
      }
    }
  };

  const CloseDelete = () => {
    setModalDelete(false);
  };

  const navigateToDetails = async (text) => {
    const value = text.toLowerCase();
    await markNotificationAsRead(item.id)
      .then(() => {
        if (value.includes("project") || value.includes("commence")) {
          if (isAdmin) {
            if (value.includes("geotechnical investigation.")) {
              navigate("/dashboard/gtiprojects");
            } else if (value.includes("project request")) {
              navigate("/dashboard/projectsadmin");
            } else {
              navigate("/dashboard/projectsadmin");
              return;
            }
          } else navigate("/dashboard/projects");
          return;
        } else if (value.includes("order") || value.includes("ordered")) {
          if (isAdmin) {
            navigate("/dashboard/ordersadmin");
          } else {
            navigate("/dashboard/orders");
            return;
          }
        } else if (value.includes("meeting")) {
          if (isAdmin) {
            navigate("/dashboard/admin-meetings");
            return;
          } else navigate("/dashboard/meetings");
          return;
        } else if (value.includes("product")) {
          if (isAdmin) {
            navigate("/dashboard/productsadmin");
            return;
          } else navigate("/dashboard/products");
          return;
        } else if (value.includes("blog post")) {
          navigate("/dashboard/blog");
        } else if (
          value.includes("private client") ||
          value.includes("corporate client")
        ) {
          navigate("/dashboard/client");
        } else if (value.includes("review")) {
          navigate("/dashboard/client");
        } else return;
      })
      .catch(() => {});
  };

  return (
    <>
      <div
        className={`flex items-start relative p-4 rounded mt-4 ${
          item.isRead ? "" : "bg-orange-50 bg-opacity-50 fw-600"
        }`}
      >
        <div className="w-24 shink-0 lg:w-auto">
          <Avatar
            src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1667909634/BOG/logobog_rmsxxc.png"
            alt="avatar"
          />
        </div>
        <div className="lg:pl-6 w-full">
          <p
            className="text-gray-600 pr-8 cursor-pointer"
            onClick={() => navigateToDetails(item.message)}
          >
            <span className="text-black">BOG Admin</span> {item.message}{" "}
          </p>
          <div className="flex items-center justify-between gap-x-5 mt-3">
            <p className="!text-[12px] lg:!text-[14px] text-gray-500">
              <ReactTimeAgo date={Date.parse(item.createdAt)} locale="en-US" />
            </p>
            {!item.isRead && (
              <p
                className="cursor-pointer flex gap-x-2  items-center"
                onClick={readNotification}
              >
                <BiCheckDouble />
                <span className="!text-[12px] lg:!fs-500 whitespace-nowrap">Mark as read</span>
              </p>
            )}
          </div>
        </div>
        <p className="top-4 right-4 absolute w-6 h-6 bg-gray-200 circle grid place-content-center">
          <RiDeleteBin5Line
            onClick={() => deleteModal(item.id)}
            className="text-red-500 cursor-pointer hover:scale-105 "
          />
        </p>
      </div>
      {modal && (
        <div
          className="fixed font-primary left-0 top-0 w-full h-screen bg-op center-item z-40"
          onClick={CloseDelete}
        >
          <div
            className="bg-white lg:w-5/12 rounded-md  overscroll-none  w-11/12 pt-8 shadow fw-500 scale-ani"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex lg:px-6 px-5">
              <div className="text-2xl pr-3 text-red-600">
                <BsExclamationCircleFill />
              </div>
              <div>
                <p className="fs-700 fw-600 mb-4">Delete Notification</p>
                <p>Are you sure you want to delete this notification?</p>
              </div>
            </div>
            <div className="bg-light rounded-b-md  py-4 mt-5 text-end px-5">
              <Button
                color="black"
                variant="outlined"
                ripple={true}
                onClick={CloseDelete}
              >
                Cancel
              </Button>
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  color="red"
                  onClick={deleteNotification}
                  className="ml-4"
                  ripple={true}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationItem;
