import React from "react";
import { FaSquareWhatsapp, FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import useFetchHook from "../../../../../hooks/useFetchHook";

const ContactDetails = () => {
  const { data, loading } = useFetchHook("/user/get/supportsocials");
  const whatsapp = data?.whatsapp ? JSON.parse(data.whatsapp) : [];
  const twitter = data?.twitter ? JSON.parse(data.twitter) : [];
  return (
    <div>
      {!loading && (
        <div className="mt-6 lg:w-9/12 mx-auto">
          <div className="mt-4 lg:mt-8 grid lg:grid-cols-2 gap-5">
            {whatsapp?.map((item) => (
              <Link
                to={`https://wa.me/${item}`}
                className="flex items-center hover:shadow-lg hover:scale-105 duration-100 gap-x-3 cursor-pointer border-[3px] border-green-500 p-2 "
                key={item}
              >
                <div className="w-[60px] shrink-0">
                  <FaSquareWhatsapp className="text-3xl lg:text-5xl shrink-0 text-[#25D366]" />
                </div>
                <div className="w-full">
                  <div className="">
                    <p>Connect with us via whatsapp on</p>
                    <p className="fw-600 text-lg">+{item}</p>
                  </div>
                  <p className="mt-2 underline fs-500 fw-500 text-end text-[#25D366]">
                    Click to start chat
                  </p>
                </div>
              </Link>
            ))}
            {twitter?.map((item, i) => (
              <div className="flex" key={i}>
                <Link
                  to={item}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center hover:shadow-lg hover:scale-105 duration-100 gap-x-3 cursor-pointer border-[3px] border-[#000000] p-2 "
                >
                  <div className="w-[60px] shrink-0">
                    <FaSquareXTwitter className="text-3xl lg:text-5xl shrink-0 text-[#000000]" />
                  </div>
                  <div className="w-full">
                  <div className="">
                    <p>Connect with us via X on</p>
                    <p className="fw-600">{item}</p>
                  </div>
                  <p className="mt-2 underline fs-500 fw-500 text-end text-[#000000]]">
                    Click to start chat
                  </p>
                </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
