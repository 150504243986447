import React from "react";
import ContactDetails from "./contact-details";

const SupportSocials = () => {
  return (
    <div>
      <div className="min-h-screen fs-500 relative">
        <div className="w-full py-8 px-4 lg:flex justify-between items-center bg-socials bg-center">
          <div className="text-white">
            <p className="text-2xl fw-600">Support Channels on Social Media</p>
            <p className="fs-400 text-gray-200 mt-2 lg:w-9/12">
              By connecting
              with us, you&apos;ll have the opportunity to interact, share your
              thoughts, and stay updated on our latest news and events.
            </p>
          </div>
        </div>
        <div className="p-4">
          <div className="w-full py-8 bg-white px-4">
            <ContactDetails/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSocials;
